// import { enableElement, disableElement } from "@rails/ujs";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hidden", "submit"];

  connect() {
    $.rails.enableElement(this.submitTarget);
  }

  checkbox(event) {
    if (event.target.checked) {
      let results = this.hiddenTarget.querySelectorAll(
        `input[type="hidden"][value="${event.target.value}"]`
      );

      for (let i = 0; i < results.length; i++) {
        results[i].remove();
      }
      this.submitTarget.classList.remove("hidden");
    } else {
      let hidden_input = document.createElement("input");
      hidden_input.type = "hidden";
      hidden_input.value = event.target.value;
      hidden_input.name = "delete_answer_ids[]";
      this.hiddenTarget.append(hidden_input);

      let checked = this.element.querySelectorAll(
        'input[type="checkbox"]:checked'
      );

      if (!checked.length) {
        this.submitTarget.classList.add("hidden");
      }
    }
  }

  submit(event) {
    this.element.submit();
  }
}
