import { Controller } from "@hotwired/stimulus";
import { Howl, Howler } from "howler";

Howler.autoUnlock = false;

export default class extends Controller {
  static targets = ["play", "pause", "progress", "seek", "duration"];

  static values = {
    url: String,
    id: String,
    initialSeek: String,
    courseName: String,
    lessonName: String,
    appName: String,
    artworkUrl: String,
  };

  connect() {
    var controller = this;
    this.sound = new Howl({
      src: [this.urlValue],
      volume: 0.5,
      html5: true,
      onpause: function () {
        clearInterval(controller.intervalId);
        controller.showPlay();
      },
      onstop: function () {
        clearInterval(controller.intervalId);
      },
      onend: function () {
        controller.showPlay();
        clearInterval(controller.intervalId);
        controller.completed = true;
        controller.sendPosition();
      },
      onplay: function () {
        controller.markPosition();
        controller.id = requestAnimationFrame(controller.step.bind(controller));
        controller.showPause();
      },
      onseek: function () {
        controller.id = requestAnimationFrame(controller.step.bind(controller));
      },
    });

    this.sound.once("load", function () {
      if ("mediaSession" in navigator) {
        navigator.mediaSession.metadata = new MediaMetadata({
          title: controller.lessonNameValue,
          artist: controller.appNameValue,
          album: controller.courseNameValue,
          artwork: [
            {
              src: controller.artworkUrlValue,
              sizes: "500x500",
              type: "image/png",
            },
          ],
        });

        navigator.mediaSession.setActionHandler("play", function () {
          controller.play();
        });

        navigator.mediaSession.setActionHandler("pause", function () {
          controller.pause();
        });

        navigator.mediaSession.setActionHandler("seekbackward", function () {
          controller.rewind();
        });

        navigator.mediaSession.setActionHandler("seekforward", function () {
          controller.forward();
        });

        navigator.mediaSession.setActionHandler("seekto", function (event) {
          controller.sound.seek(event.seekTime);
        });
      }

      controller.durationTarget.innerHTML = controller.formatTime(
        controller.sound.duration()
      );
      if (controller.initialSeekValue) {
        controller.progressTarget.value = controller.initialSeekValue;
        controller.sound.seek(controller.initialSeekValue);
      }
    });
  }

  step() {
    var seek = this.sound.seek() || 0;

    for (let tgt of this.progressTargets) {
      tgt.value = seek;
    }

    for (let tgt of this.seekTargets) {
      tgt.innerHTML = this.formatTime(seek);
    }

    // this.progressTarget.value = seek;
    // this.seekTarget.innerHTML = this.formatTime(seek);

    // If sound is still playing, continue stepping
    if (this.sound.playing()) {
      this.id = requestAnimationFrame(this.step.bind(this));
    }
  }

  play() {
    if (this.sound.playing()) {
      return;
    }

    this.sound.play();
  }

  pause() {
    if (!this.sound.playing()) {
      return;
    }

    this.sound.pause();
  }

  forward() {
    this.sound.seek(this.sound.seek() + 15);
  }

  rewind() {
    if (this.sound.seek() <= 15) {
      this.sound.seek(this.sound.seek(0));
    } else {
      this.sound.seek(this.sound.seek() - 15);
    }
  }

  seek() {
    cancelAnimationFrame(this.id);
    for (let tgt of this.progressTargets) {
      var seekedTime = event.currentTarget.value;
      this.sound.seek(seekedTime);
    }
  }

  showPause() {
    this.togglePlay("inline-block", "hidden");
  }

  showPlay() {
    this.togglePlay("hidden", "inline-block");
  }

  formatTime(seek) {
    var minutes, seconds;

    minutes = parseInt(seek / 60, 10);
    seconds = parseInt(seek % 60, 10);
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return minutes + ":" + seconds;
  }

  togglePlay(class1, class2) {
    for (let tgt of this.playTargets) {
      tgt.classList.replace(class1, class2);
    }

    for (let tgt of this.pauseTargets) {
      tgt.classList.replace(class2, class1);
    }
  }

  markPosition() {
    if (this.idValue) {
      var controller = this;
      clearInterval(this.intervalId);
      this.intervalId = window.setInterval(function () {
        controller.sendPosition();
      }, 5000);
    }
  }

  sendPosition() {
    fetch("/subscription_audios/" + this.idValue, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CSRF-Token": this.getMetaValue("csrf-token"),
      },
      body: JSON.stringify({
        position: parseInt(this.sound.seek()),
        completed: this.completed,
      }),
    });
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute("content");
  }
}
