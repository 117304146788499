import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "selectMain", "id"];
  connect() {
    console.log("form_controller.js connected");
  }

  submit(event) {
    console.log("submitting");
    this.element.submit();
  }

  changeSubmitForm() {
    var id = event.currentTarget.selectedOptions[0].value;
    this.idTarget.value = id;
    this.formTarget.submit();
  }

  submitForm() {
    this.idTarget.value = this.selectMainTarget.selectedOptions[0].value;
    this.formTarget.submit();
  }
}
