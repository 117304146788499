import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  connect() {
    const first = document.querySelector(".slide");
    const slide = () => {
      const before = document.querySelector(".showing");
      if (before) {
        before.classList.remove("showing");
        const next = before.nextElementSibling;
        if (next) {
          next.classList.add("showing");
        } else {
          first.classList.add("showing");
        }
      } else {
        first.classList.add("showing");
      }
    };
    slide();
    setInterval(slide, 3500);
  }
}
