import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import ProgressBar from "progressbar.js";

export default class extends Controller {
  static values = { color: String, url: String };

  connect() {
    document.querySelector("#circle_container").innerHTML = "";
    var urlValue = this.urlValue;
    var bar = new ProgressBar.Circle("#circle_container", {
      color: "#aaa",
      // This has to be the same size as the maximum width to
      // prevent clipping
      strokeWidth: 0,
      trailWidth: 8,
      easing: "easeInOut",
      duration: 7000,
      text: {
        autoStyleContainer: false,
      },
      from: {
        color: this.colorValue,
        width: 8,
      },
      to: {
        color: this.colorValue,
        width: 8,
      },
      // Set default step function for all animate calls
      step: function (state, circle) {
        circle.path.setAttribute("stroke", state.color);
        circle.path.setAttribute("stroke-width", state.width);

        var value = Math.round(circle.value() * 100);
        if (value === 0) {
          circle.setText("");
        } else {
          circle.setText(value + "%");
        }
      },
    });
    bar.text.style.fontSize = "1.5rem";
    bar.animate(1.0); // Number from 0.0 to 1.0

    setTimeout(function () {
      window.location.href = urlValue;
    }, 7000);
  }
}
