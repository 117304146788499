import { Controller } from "@hotwired/stimulus";
import mixpanel from "mixpanel-browser";

export default class extends Controller {
  static values = { session: String, track: String, app: String };

  connect() {
    mixpanel.init("fef2832433a8fe967a6ed64475cc097c");
    mixpanel.identify(this.sessionValue);
    mixpanel.track(this.trackValue, { App: this.appValue });
  }
}
