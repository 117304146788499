import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    number: String,
    keyword: String,
  };

  static targets = ["number", "keyword"];

  select(event) {
    this.numberValue = event.target.dataset.number;
    this.keywordValue = event.target.dataset.keyword;

    this.numberTarget.innerHTML = this.numberValue;
    this.keywordTarget.innerHTML = this.keywordValue;

    document
      .querySelector(".select-provider-container")
      .classList.add("hidden");
    document
      .querySelector(".selected-provider-container")
      .classList.remove("hidden");
  }

  selectMobile(event) {
    document
      .querySelector(".select-provider-container")
      .classList.remove("hidden");
  }

  closeModal() {
    document
      .querySelector(".select-provider-container")
      .classList.add("hidden");
  }
}
